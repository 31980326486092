<template>
  <div class="paywall">
    <div class="paywall__info-container">
      <div class="paywall__info">
        <slot name="paywallInfo">
          <p class="paywall__info-title">
            Полная версия каталога доступна после заключения договора
          </p>
          <p class="paywall__info-text">
            Пройдите квалификацию и заключите договор, чтобы получить полный доступ к платформе
          </p>
        </slot>
      </div>
      <div class="paywall__buttons">
        <MButton
          :size="MM.EButtonSizes.Medium"
          @click="onClientCreateClick"
        >
          Стать покупателем
        </MButton>
        <MButton
          class="login"
          :type="MM.EButtonTypes.Text"
          :loading="loading"
          @click="onShowLoginModal"
        >
          Войти
        </MButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MM } from 'types';
import { AuthManagerService } from 'services/auth/authManager.service';
import { EClientApprovalType } from 'enums/client/clientApprovalType.enum';
import { useAuthFnHelper } from 'composables/useAuthHelper';

const { showLoginModal } = useAuthFnHelper();
const { onCLickClientApprovalModal } = useClientApproval();

const loading = ref(false);

async function onShowLoginModal() {
  try {
    loading.value = true;
    await showLoginModal(loginModalCallback, undefined, false, false);
  } finally {
    loading.value = false;
  }
}

async function loginModalCallback(): Promise<void> {
  await nextTick(async () => await AuthManagerService.checkSupplierRedirect());
}

function onClientCreateClick() {
  onCLickClientApprovalModal(EClientApprovalType.Client);
}
</script>

<style lang="scss" scoped>
.paywall {
  display: flex;
  align-items: center;
  background: $color-gray-extra-light;
  background-image: url('/images/paywall_banner.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  border: 1px solid $color-gray-light;
  border-radius: 8px;
  box-shadow: 0px -12px 44px 0px rgba(0, 0, 0, 0.12);
  padding: 48px 40px;
  gap: 60px;

  &::before {
    content: '';
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    display: block;
    width: 100%;
    height: 97px;
    padding: 48px 40px;
    position: absolute;
    top: -97px;  
    left: 0;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: 64px;
  }

  &__info {
    max-width: 760px;
  }

  &__info-title {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.1;
  }

  &__info-text {
    @include text-r16;

    max-width: 457px;
    margin: 0;
  }

  &__buttons {
    & .login {
      margin-left: 40px;
    }
  }
}

.paywall {
  &.productSlug {
    padding: 24px 32px 32px;

    & .paywall__info-container {
      gap: 32px;
    }
  }
}

@include maxw(1279) {
  .paywall {
    padding: 48px 20px;

    &__info-title {
      font-size: 24px;
      line-height: 1.333;
    }
  }
}

@include maxw(767) {
  .paywall {
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 20px;
    gap: 40px;

    &__info-container {
      gap: 48px;
    }

    &__info-title {
      font-size: 20px;
      line-height: 1.4;
    }
  }
}
</style>
